/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, TitleMain, Image, Title, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="--left pb--20 pt--50" name={"einleitung"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--20 pr--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--18 w--300 swpf--uppercase ls--04 lh--2 pb--08 pt--08" content={"Straßenname"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s3 --left pb--60 pt--10" anim={"2"} name={"einleitung-2"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--20 pr--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box fs--154 w--300 swpf--uppercase ls--01 lh--12" style={{"maxWidth":""}} content={"Café Romanca<br>Berlin—Stadt"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--20 pt--20" name={"einleitung-3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/fullscreen_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/fullscreen_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"information"}>
          
          <ColumnWrap className="column__flex --right el--2 pb--12 pl--20 pr--20 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2" style={{"maxWidth":550}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":610}} border={null}>
              
              <Text className="text-box ff--3 fs--16 w--300 swpf--uppercase ls--04 lh--2 mt--02" style={{"maxWidth":""}} content={"SO — DO"}>
              </Text>

              <Title className="title-box fs--72 w--300 ls--0 lh--1 mt--10" style={{"maxWidth":""}} content={"9:00 — 23:00"}>
              </Title>

              <Text className="text-box ff--3 fs--16 w--300 swpf--uppercase ls--04 lh--2 mt--40" style={{"maxWidth":""}} content={"FRE–SAT"}>
              </Text>

              <Title className="title-box fs--72 w--300 ls--0 lh--1 mt--10" style={{"maxWidth":""}} content={"11:00 — 02:00"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"fotogallerie"}>
          
          <ColumnWrap className="column__flex --right el--1 pl--20 pr--20 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":""}} border={null}>
              
              <Image className="--left" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg"} svg={false} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--50" style={{"maxWidth":570}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--20" name={"navigation"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --right el--2 pl--20 pr--20 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--left ff--2 fs--72 ls--0 mb--0 pl--0 pr--0" innerClassName="pl--0 pr--0" href={"/de/menu"} content={"Menü"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--left ff--2 fs--72 w--300 ls--0 mb--0 mt--0 pl--0 pr--0" innerClassName="pl--0" href={"/de/fotogalerie"} content={"Fotogalerie"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--left ff--2 fs--72 ls--0 mb--06 mt--0 pl--0 pr--0" innerClassName="pl--0 pr--0" href={"/de/rezervace"} content={"Buchung"}>
              </Button>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--50" style={{"maxWidth":428}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"buchung"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 mb--80 pl--20 pr--20 flex--top" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":510}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--0" style={{"maxWidth":570}} content={"+49 797 811 2X05"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--0" style={{"maxWidth":570}} content={"info@vase-stranky.com"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--0" style={{"maxWidth":570}} content={"Oeder Weg 22"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 fs--22 swpf--uppercase ls--30 mt--40" content={"Buchung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}} border={null}>
          
          <ColumnWrap className="column__flex --right el--2 pb--30 pl--20 pr--20 pt--30 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--62 swpf--uppercase" content={"<span style=\"color: var(--color-dominant);\">Café Romanca</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--30" style={{"maxWidth":570}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--0 pl--0" innerClassName="pb--02 pl--0" content={"<span style=\"color: var(--color-dominant);\">Nach oben</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/fotogallerie"} content={"<span style=\"color: var(--color-dominant);\">Fotogalerie</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/menu"} content={"<span style=\"color: var(--color-dominant);\">Menü</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/buchung"} content={"<span style=\"color: var(--color-dominant);\">Buchung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}